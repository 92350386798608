<template>
  <div>
    <b-card>
    <b-row class="match-height">
      <b-col>
        <statistic-card-vertical
          :statistic="subModuleNameWithCount[0]?.count"
          color="info"
          icon="BoxIcon"
          button-name="Division"
          to="/admin/division"
        />
      </b-col>
      <b-col>
        <statistic-card-vertical
          color="warning"
          icon="GridIcon"
          :statistic="subModuleNameWithCount[1]?.count"
          button-name="Department"
          to="/admin/department"
        />
      </b-col>
      <b-col>
        <statistic-card-vertical
          color="success"
          icon="AwardIcon"
          :statistic="subModuleNameWithCount[2]?.count"
          button-name="Designation"
          to="/admin/designation"
        />
      </b-col>
      <b-col>
        <statistic-card-vertical
          color="primary"
          icon="UsersIcon"
          :statistic="subModuleNameWithCount[3]?.count"
          button-name="Users"
          to="/admin/users"
        />
      </b-col>
      <b-col>
        <statistic-card-vertical
          color="danger"
          icon="ShieldIcon"
          :statistic="subModuleNameWithCount[4]?.count"
          button-name="Roles"
          to="/admin/Roles"
        />
      </b-col>
      
    </b-row>
  </b-card>
    
  </div>
</template>
  
  <script>
import {
  BCard,
  BRow,
  BCol,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BMedia,
  BMediaAside,
  BMediaBody,
  BFormSelect,
  BPagination,
  BTable,
  BFormCheckbox,
  BAvatar,
  BBadge,
  BFormGroup,
  BInputGroup,
  BLink,
  BSpinner,
} from "bootstrap-vue";

import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "SubModulesCountWidget",
  components: {
    BCard,
    BRow,
    BCol,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    StatisticCardVertical,
    BFormSelect,
    BPagination,
    BTable,
    BFormCheckbox,
    BAvatar,
    BBadge,
    BFormGroup,
    BInputGroup,
    BLink,
    BSpinner,
  },

  data() {
    return {
      subModuleNameWithCount: [],
      totalOfficeTime: "10/50",
    };
  },

  async created() {
    try {
      const subModuleNameWithCount = await this.getPanelCount();

      this.subModuleNameWithCount = subModuleNameWithCount?.data?.data;
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },
  methods: {
    async getPanelCount() {
      return await this.$api.get("api/dashboard/admin-dashboard/panel-count");
    },
  },
};
</script>
  
  <style lang="scss">
.custom-card-statistics .statistics-body {
  padding: 0rem 2.4rem 1rem !important;
}
// .table-custom-style {
//   font-size: 13px !important;
//   white-space: nowrap !important;
//   min-height: 140px !important;
//   tr,
//   th,
//   td {
//     vertical-align: middle !important;
//     text-align: center !important;
//   }
// }

// .table-cell-margin {
//   margin: 8px 0px;
// }
.lead-table {
  padding: 0px !important;
}
</style>
  