var render = function () {
  var _vm$leadStatusCount, _vm$leadStatusCount2, _vm$leadStatusCount3, _vm$leadStatusCount4, _vm$leadStatusCount5, _vm$leadStatusCount6, _vm$leadStatusCount7, _vm$leadStatusCount8, _vm$leadStatusCount9, _vm$leadStatusCount10;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "card-statistics custom-card-statistics",
    staticStyle: {
      "margin-bottom": "10px !important"
    },
    attrs: {
      "no-body": ""
    }
  }, [_c('b-row', [_c('b-card-header', [_c('label', {
    staticClass: "custom-font"
  }, [_c('b', [_vm._v("Lead Status Summary")])])]), _c('div', {
    staticClass: "button-style"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0 mr-1",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.goLead
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "ClipboardIcon"
    }
  })], 1)], 1)], 1), _c('b-card-body', {
    staticClass: "statistics-body"
  }, [_c('b-row', [_c('b-media', {
    staticClass: "ml-1",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', [_c('b-avatar', {
    attrs: {
      "size": "28",
      "variant": "light-primary"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "15",
      "icon": "DollarSignIcon"
    }
  })], 1)], 1), _c('b-media-body', {
    staticClass: "my-auto"
  }, [_c('h4', {
    staticClass: "font-weight-bolder mb-0 text-primary text-primary"
  }, [[_vm._v(" " + _vm._s((_vm$leadStatusCount = _vm.leadStatusCount) === null || _vm$leadStatusCount === void 0 ? void 0 : _vm$leadStatusCount.total_lead_count) + " ")]], 2), _c('b-card-text', {
    staticClass: "font-small-3 mb-0"
  }, [_vm._v(" Total ")])], 1)], 1), _c('b-media', {
    staticClass: "ml-1",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', [_c('b-avatar', {
    attrs: {
      "size": "28",
      "variant": "light-success"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "15",
      "icon": "CheckCircleIcon"
    }
  })], 1)], 1), _c('b-media-body', {
    staticClass: "my-auto"
  }, [_c('h4', {
    staticClass: "font-weight-bolder mb-0 text-primary"
  }, [[_vm._v(" " + _vm._s((_vm$leadStatusCount2 = _vm.leadStatusCount) === null || _vm$leadStatusCount2 === void 0 ? void 0 : _vm$leadStatusCount2.converted_lead_count) + " ")]], 2), _c('b-card-text', {
    staticClass: "font-small-3 mb-0"
  }, [_vm._v(" Converted ")])], 1)], 1), _c('b-media', {
    staticClass: "ml-1",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', [_c('b-avatar', {
    attrs: {
      "size": "28",
      "variant": "light-danger"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "15",
      "icon": "XOctagonIcon"
    }
  })], 1)], 1), _c('b-media-body', {
    staticClass: "my-auto"
  }, [_c('h4', {
    staticClass: "font-weight-bolder mb-0 text-primary"
  }, [[_vm._v(" " + _vm._s((_vm$leadStatusCount3 = _vm.leadStatusCount) === null || _vm$leadStatusCount3 === void 0 ? void 0 : _vm$leadStatusCount3.lost_lead_count) + " ")]], 2), _c('b-card-text', {
    staticClass: "font-small-3 mb-0"
  }, [_vm._v(" Lost ")])], 1)], 1), _c('b-media', {
    staticClass: "ml-1",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', [_c('b-avatar', {
    attrs: {
      "size": "28",
      "variant": "light-info"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "15",
      "icon": "HelpCircleIcon"
    }
  })], 1)], 1), _c('b-media-body', {
    staticClass: "my-auto"
  }, [_c('h4', {
    staticClass: "font-weight-bolder mb-0 text-primary"
  }, [!isNaN((_vm$leadStatusCount4 = _vm.leadStatusCount) === null || _vm$leadStatusCount4 === void 0 ? void 0 : _vm$leadStatusCount4.total_lead_count) && !isNaN((_vm$leadStatusCount5 = _vm.leadStatusCount) === null || _vm$leadStatusCount5 === void 0 ? void 0 : _vm$leadStatusCount5.converted_lead_count) && !isNaN((_vm$leadStatusCount6 = _vm.leadStatusCount) === null || _vm$leadStatusCount6 === void 0 ? void 0 : _vm$leadStatusCount6.lost_lead_count) ? [_vm._v(" " + _vm._s(((_vm$leadStatusCount7 = _vm.leadStatusCount) === null || _vm$leadStatusCount7 === void 0 ? void 0 : _vm$leadStatusCount7.total_lead_count) - (((_vm$leadStatusCount8 = _vm.leadStatusCount) === null || _vm$leadStatusCount8 === void 0 ? void 0 : _vm$leadStatusCount8.converted_lead_count) + ((_vm$leadStatusCount9 = _vm.leadStatusCount) === null || _vm$leadStatusCount9 === void 0 ? void 0 : _vm$leadStatusCount9.lost_lead_count))) + " ")] : _vm._e()], 2), _c('b-card-text', {
    staticClass: "font-small-3 mb-0"
  }, [_vm._v(" Others ")])], 1)], 1), _c('b-media', {
    staticClass: "ml-1",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', [_c('b-avatar', {
    attrs: {
      "size": "28",
      "variant": "light-warning"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "15",
      "icon": "PauseCircleIcon"
    }
  })], 1)], 1), _c('b-media-body', {
    staticClass: "my-auto"
  }, [_c('h4', {
    staticClass: "font-weight-bolder mb-0 text-primary"
  }, [[_vm._v(" " + _vm._s((_vm$leadStatusCount10 = _vm.leadStatusCount) === null || _vm$leadStatusCount10 === void 0 ? void 0 : _vm$leadStatusCount10.not_contacted) + " ")]], 2), _c('b-card-text', {
    staticClass: "font-small-3 mb-0"
  }, [_vm._v(" Not Contacted ")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }