<template>
  <!-- badge -->

  <b-card
    no-body
    class="card-statistics custom-card-statistics"
    style="margin-bottom: 10px !important"
  >
  <b-row>
    <b-card-header>
      <label class="custom-font"><b>All Asset Summary</b></label>
    </b-card-header>

    <div class="button-style">
      <b-button
        class="flex-shrink-0 mr-1"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="goAsset"
      >
      <feather-icon icon="ClipboardIcon" />
      </b-button>
    </div>
  </b-row>



    <b-card-body class="statistics-body">
      <b-row>

        <b-media no-body class="ml-1">
          <b-media-aside>
            <b-avatar size="28" variant="light-info">
              <feather-icon size="15" icon="HardDriveIcon" />
            </b-avatar>
          </b-media-aside>
          <b-media-body class="my-auto">
            <h4 class="font-weight-bolder mb-0">{{ assetStatusCount?.total_category_count }}</h4>
            <b-card-text class="font-small-3 mb-0"> Categories </b-card-text>
          </b-media-body>
        </b-media>

        <b-media no-body class="ml-1">
          <b-media-aside>
            <b-avatar size="28" variant="light-primary">
              <feather-icon size="15" icon="HardDriveIcon" />
            </b-avatar>
          </b-media-aside>
          <b-media-body class="my-auto">
            <h4 class="font-weight-bolder mb-0">{{ assetStatusCount?.total_count }}</h4>
            <b-card-text class="font-small-3 mb-0">
              Total Asset Count
            </b-card-text>
          </b-media-body>
        </b-media>

        <b-media no-body class="ml-1">
          <b-media-aside>
            <b-avatar size="28" variant="light-success">
              <feather-icon size="15" icon="HardDriveIcon" />
            </b-avatar>
          </b-media-aside>
          <b-media-body class="my-auto">
            <h4 class="font-weight-bolder mb-0">{{ assetStatusCount?.available_count }}</h4>
            <b-card-text class="font-small-3 mb-0">
              Available Asset Count
            </b-card-text>
          </b-media-body>
        </b-media>



        <b-media no-body class="ml-1">
          <b-media-aside>
            <b-avatar size="28" variant="light-danger">
              <feather-icon size="15" icon="HardDriveIcon" />
            </b-avatar>
          </b-media-aside>
          <b-media-body class="my-auto">
            <h4 class="font-weight-bolder mb-0">{{ assetStatusCount?.total_count - assetStatusCount?.available_count}}</h4>
            <b-card-text class="font-small-3 mb-0"> Unavailable Asset Count </b-card-text>
          </b-media-body>
        </b-media>

      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BMedia,
  BMediaAside,
  BMediaBody,
  BFormSelect,
  BPagination,
  BTable,
  BFormCheckbox,
  BAvatar,
  BBadge,
  BFormGroup,
  BInputGroup,
  BLink,
  BButton,

} from "bootstrap-vue";

import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import { heightFade } from "@core/directives/animations";

export default {
  name: "AssetSummaryCountWidget",
  components: {
    BCard,
    BRow,
    BCol,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    StatisticCardVertical,
    BFormSelect,
    BPagination,
    BTable,
    BFormCheckbox,
    BAvatar,
    BBadge,
    BFormGroup,
    BInputGroup,
    BLink,
    BButton,

  },

  directives: {
    Ripple,
    heightFade,
  },

  data() {
    return {
      assetStatusCount: {
        available_count: 0,
        total_category_count: 0,
        total_count: 0,
      },
    };
  },

  async created() {
    try {
      const assetStatusCount = await this.getAssetSummaryCount();

      this.assetStatusCount = assetStatusCount?.data;
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {
    async getAssetSummaryCount() {
      return await this.$api.get(
        "api/dashboard/asset-summary-count"
      );
  },

  goAsset() {
    this.$router.push({
        name: "admin-assets",
      });
  },

},

};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.table-custom-style-att {
  white-space: nowrap !important;
  min-height: 140px !important;
  tr,
  th,
  td {
    vertical-align: middle !important;
    text-align: center !important;
    font-size: 12px !important;
  }
}
.custom-font {
  font-size: 13px !important;
}
</style>
