<template>
    <b-card
      no-body
      class="card-statistics custom-card-statistics"
      style="margin-bottom: 10px !important"
    >
      <b-card-header>
        <label class="custom-font"><b>Project Status Summary</b></label>
      </b-card-header>

      <b-card-body class="statistics-body">
        <b-row>
          <b-media no-body class="ml-4">
            <b-media-aside>
              <b-avatar size="28" variant="light-primary">
                <feather-icon size="15" icon="DollarSignIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0 text-primary text-primary">
                <template>
                  {{ projectStatusCount?.total_projects }}
                </template>
                <!-- <template>
                  <b-spinner small variant="primary" />
                </template> -->
              </h4>
              <b-card-text class="font-small-3 mb-0"> Total Project </b-card-text>
            </b-media-body>
          </b-media>
          <b-media no-body class="ml-4">
            <b-media-aside>
              <b-avatar size="28" variant="light-success">
                <feather-icon size="15" icon="CheckCircleIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0 text-primary">
                <template>
                 {{ projectStatusCount?.all_approved_projects }}
                </template>
                <!-- <template v-else>
                  <b-spinner small variant="primary" />
                </template> -->
              </h4>
              <b-card-text class="font-small-3 mb-0"> All Approved Project </b-card-text>
            </b-media-body>
          </b-media>

          <b-media no-body class="ml-4">
            <b-media-aside>
              <b-avatar size="28" variant="light-danger">
                <feather-icon size="15" icon="XOctagonIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0 text-primary">
                <template>
                  {{ projectStatusCount?.all_pending_projects }}
                </template>
                <!-- <template v-else>
                  <b-spinner small variant="primary" />
                </template> -->
              </h4>
              <b-card-text class="font-small-3 mb-0"> All Pending Project </b-card-text>
            </b-media-body>
          </b-media>
        </b-row>

        <br>

        <b-row>

          <b-media no-body class="ml-4">
            <b-media-aside>
              <b-avatar size="28" variant="light-danger">
                <feather-icon size="15" icon="CheckCircleIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0 text-primary">
                <template>
                  {{ projectStatusCount?.not_started_projects }}
                </template>
                <!-- <template v-else>
                  <b-spinner small variant="primary" />
                </template> -->
              </h4>
              <b-card-text class="font-small-3 mb-0"> Not Started Project </b-card-text>
            </b-media-body>
          </b-media>
          <b-media no-body class="ml-4">
            <b-media-aside>
              <b-avatar size="28" variant="light-primary">
                <feather-icon size="15" icon="CheckCircleIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0 text-primary">
                <template>
                  {{ projectStatusCount?.ongoing_projects }}
                </template>
                <!-- <template v-else>
                  <b-spinner small variant="primary" />
                </template> -->
              </h4>
              <b-card-text class="font-small-3 mb-0"> Ongoing Project </b-card-text>
            </b-media-body>
          </b-media>

          <b-media no-body class="ml-4">
            <b-media-aside>
              <b-avatar size="28" variant="light-success">
                <feather-icon size="15" icon="CheckCircleIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0 text-primary">
                <template>
                  {{ projectStatusCount?.finished_projects }}
                </template>
                <!-- <template v-else>
                  <b-spinner small variant="primary" />
                </template> -->
              </h4>
              <b-card-text class="font-small-3 mb-0"> Finished Project </b-card-text>
            </b-media-body>
          </b-media>

        </b-row>
      </b-card-body>
    </b-card>
  </template>

    <script>
  import {
    BCard,
    BRow,
    BCol,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    BFormSelect,
    BPagination,
    BTable,
    BFormCheckbox,
    BAvatar,
    BBadge,
    BFormGroup,
    BInputGroup,
    BLink,
    BSpinner,
  } from "bootstrap-vue";

  import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

  export default {
    name: "ProjectSummaryCount",
    components: {
      BSpinner,
      BCard,
      BRow,
      BCol,
      BCardHeader,
      BCardTitle,
      BCardText,
      BCardBody,
      BMedia,
      BMediaAside,
      BMediaBody,
      StatisticCardVertical,
      BFormSelect,
      BPagination,
      BTable,
      BFormCheckbox,
      BAvatar,
      BBadge,
      BFormGroup,
      BInputGroup,
      BLink,
    },

    data() {
      return {
        projectStatusCount: {
          total_projects: 0,
          all_approved_projects: 0,
          all_pending_projects: 0,
          not_started_projects: 0,
          ongoing_projects: 0,
          finished_projects: 0,
        },
      };
    },

    async created() {
      try {
        const projectStatusCount = await this.getProjectSummaryCount();

        this.projectStatusCount = projectStatusCount?.data?.data;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    methods: {
      async getProjectSummaryCount() {
        return await this.$api.get(
          "api/dashboard/project-status-summary"
        );
      },
    },
  };
  </script>

    <style lang="scss">
  .custom-card-statistics .statistics-body {
    padding: 0rem 2.4rem 1rem !important;
  }

  .lead-table {
    padding: 0px !important;
  }
  </style>
