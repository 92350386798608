var render = function () {
  var _vm$projectStatusCoun, _vm$projectStatusCoun2, _vm$projectStatusCoun3, _vm$projectStatusCoun4, _vm$projectStatusCoun5, _vm$projectStatusCoun6;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "card-statistics custom-card-statistics",
    staticStyle: {
      "margin-bottom": "10px !important"
    },
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', [_c('label', {
    staticClass: "custom-font"
  }, [_c('b', [_vm._v("Project Status Summary")])])]), _c('b-card-body', {
    staticClass: "statistics-body"
  }, [_c('b-row', [_c('b-media', {
    staticClass: "ml-4",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', [_c('b-avatar', {
    attrs: {
      "size": "28",
      "variant": "light-primary"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "15",
      "icon": "DollarSignIcon"
    }
  })], 1)], 1), _c('b-media-body', {
    staticClass: "my-auto"
  }, [_c('h4', {
    staticClass: "font-weight-bolder mb-0 text-primary text-primary"
  }, [[_vm._v(" " + _vm._s((_vm$projectStatusCoun = _vm.projectStatusCount) === null || _vm$projectStatusCoun === void 0 ? void 0 : _vm$projectStatusCoun.total_projects) + " ")]], 2), _c('b-card-text', {
    staticClass: "font-small-3 mb-0"
  }, [_vm._v(" Total Project ")])], 1)], 1), _c('b-media', {
    staticClass: "ml-4",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', [_c('b-avatar', {
    attrs: {
      "size": "28",
      "variant": "light-success"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "15",
      "icon": "CheckCircleIcon"
    }
  })], 1)], 1), _c('b-media-body', {
    staticClass: "my-auto"
  }, [_c('h4', {
    staticClass: "font-weight-bolder mb-0 text-primary"
  }, [[_vm._v(" " + _vm._s((_vm$projectStatusCoun2 = _vm.projectStatusCount) === null || _vm$projectStatusCoun2 === void 0 ? void 0 : _vm$projectStatusCoun2.all_approved_projects) + " ")]], 2), _c('b-card-text', {
    staticClass: "font-small-3 mb-0"
  }, [_vm._v(" All Approved Project ")])], 1)], 1), _c('b-media', {
    staticClass: "ml-4",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', [_c('b-avatar', {
    attrs: {
      "size": "28",
      "variant": "light-danger"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "15",
      "icon": "XOctagonIcon"
    }
  })], 1)], 1), _c('b-media-body', {
    staticClass: "my-auto"
  }, [_c('h4', {
    staticClass: "font-weight-bolder mb-0 text-primary"
  }, [[_vm._v(" " + _vm._s((_vm$projectStatusCoun3 = _vm.projectStatusCount) === null || _vm$projectStatusCoun3 === void 0 ? void 0 : _vm$projectStatusCoun3.all_pending_projects) + " ")]], 2), _c('b-card-text', {
    staticClass: "font-small-3 mb-0"
  }, [_vm._v(" All Pending Project ")])], 1)], 1)], 1), _c('br'), _c('b-row', [_c('b-media', {
    staticClass: "ml-4",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', [_c('b-avatar', {
    attrs: {
      "size": "28",
      "variant": "light-danger"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "15",
      "icon": "CheckCircleIcon"
    }
  })], 1)], 1), _c('b-media-body', {
    staticClass: "my-auto"
  }, [_c('h4', {
    staticClass: "font-weight-bolder mb-0 text-primary"
  }, [[_vm._v(" " + _vm._s((_vm$projectStatusCoun4 = _vm.projectStatusCount) === null || _vm$projectStatusCoun4 === void 0 ? void 0 : _vm$projectStatusCoun4.not_started_projects) + " ")]], 2), _c('b-card-text', {
    staticClass: "font-small-3 mb-0"
  }, [_vm._v(" Not Started Project ")])], 1)], 1), _c('b-media', {
    staticClass: "ml-4",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', [_c('b-avatar', {
    attrs: {
      "size": "28",
      "variant": "light-primary"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "15",
      "icon": "CheckCircleIcon"
    }
  })], 1)], 1), _c('b-media-body', {
    staticClass: "my-auto"
  }, [_c('h4', {
    staticClass: "font-weight-bolder mb-0 text-primary"
  }, [[_vm._v(" " + _vm._s((_vm$projectStatusCoun5 = _vm.projectStatusCount) === null || _vm$projectStatusCoun5 === void 0 ? void 0 : _vm$projectStatusCoun5.ongoing_projects) + " ")]], 2), _c('b-card-text', {
    staticClass: "font-small-3 mb-0"
  }, [_vm._v(" Ongoing Project ")])], 1)], 1), _c('b-media', {
    staticClass: "ml-4",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', [_c('b-avatar', {
    attrs: {
      "size": "28",
      "variant": "light-success"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "15",
      "icon": "CheckCircleIcon"
    }
  })], 1)], 1), _c('b-media-body', {
    staticClass: "my-auto"
  }, [_c('h4', {
    staticClass: "font-weight-bolder mb-0 text-primary"
  }, [[_vm._v(" " + _vm._s((_vm$projectStatusCoun6 = _vm.projectStatusCount) === null || _vm$projectStatusCoun6 === void 0 ? void 0 : _vm$projectStatusCoun6.finished_projects) + " ")]], 2), _c('b-card-text', {
    staticClass: "font-small-3 mb-0"
  }, [_vm._v(" Finished Project ")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }