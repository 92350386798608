<template>
  <b-card>
    <label class="custom-font mb-1"><b>Leave Balance Summary</b></label>
    <b-table responsive="sm" :items="items" sticky-header height="300px" />
  </b-card>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner,
  BCol,
  BRow,
  BFormDatepicker,
  BFormTextarea,
  BLink,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardText,
  BTable,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import { EventBus } from "@/helpers/event-bus";
import { minutesToHours } from "@/helpers/helpers";

export default {
  name: "LessWorkHourWidget",
  components: {
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
    BCol,
    BRow,
    BFormDatepicker,
    BFormTextarea,
    BLink,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardText,
    BTable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // filter
      items: [
        {
          name: "",
          total_worked_hour: "",
          less_duty_hour:  "",
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
  },

  async created() {
    try {
      this.loadItems();
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },
  methods: {
    formatDate(value) {
      if (value) {
        return this.$moment(value).format("MMM Do YYYY");
      }
      return "Not Set";
    },
    formatFnTableExtraLessDutyHours(value) {
      if (value) {
        return minutesToHours(value);
      }else{
        return "0";
      }
    },
    formatFnTableTotalWorkedMinutes(value) {
      if (value) {
        return minutesToHours(value);
      }else{
        return "0";
      }
    },

    // onShowPage(id) {
    //   this.$router.push({
    //     name: "admin-attendance-details",
    //     params: { id },
    //   });
    // },

    async getAttendanceData() {
      return await this.$api.get("api/dashboard/lastDay-lessWork-hour?include=user");
    },

    async loadItems() {
      try {
        const attendanceData = await this.getAttendanceData();

        if (attendanceData?.data?.data.length > 0) {
          this.items = attendanceData?.data?.data.map((item) => {
            return {
              name: item?.user?.data?.name,
              total_worked_hour: this.formatFnTableExtraLessDutyHours(item?.expected_duty_minutes),
              less_duty_hour: this.formatFnTableTotalWorkedMinutes(item?.extra_less_duty_minutes),
            };
          });
        }

      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
  },
};
</script>

    <style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.table-custom-style-att {
  white-space: nowrap !important;
  min-height: 140px !important;
  tr,
  th,
  td {
    vertical-align: middle !important;
    text-align: center !important;
    font-size: 12px !important;
  }
}
.custom-font {
  font-size: 13px !important;
}
</style>
