<template>
  <b-card>
    <b-row style="margin-bottom: 10px;">
      <label class="custom-font mb-1"><b>Due Invoice Summary [Last 30 Days]</b></label>

      <div class="button-style">
        <b-button
          class="flex-shrink-0 mr-1"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="goInvoice"
        >
        <feather-icon icon="ClipboardIcon" />

        </b-button>
      </div>
    </b-row>
    <b-table responsive="sm" :items="items" sticky-header height="300px" />
  </b-card>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner,
  BCol,
  BRow,
  BFormDatepicker,
  BFormTextarea,
  BLink,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardText,
  BTable,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import { EventBus } from "@/helpers/event-bus";

export default {
  name: "FinanceDueInvoiceWidget",
  components: {
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
    BCol,
    BRow,
    BFormDatepicker,
    BFormTextarea,
    BLink,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardText,
    BTable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // filter
      items: [
        {
          due_data: "",
          invoice_no: "No Data Found",
          sub_total_amount: "",
          grand_total_amount:  "",
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
  },

  async created() {
    try {
      this.loadItems();
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },
  methods: {
    formatDate(value) {
      if (value) {
        return this.$moment(value).format("MMM Do YYYY");
      }
      return "Not Set";
    },

    goInvoice() {
      this.$router.push({
        name: "admin-invoices",
      });
    },

    // onShowPage(id) {
    //   this.$router.push({
    //     name: "admin-attendance-details",
    //     params: { id },
    //   });
    // },

    async getInvoiceData() {
      return await this.$api.get("api/dashboard/invoice-summary");
    },

    async loadItems() {
      try {
        const invoiceData = await this.getInvoiceData();

        if (invoiceData?.data?.data.length > 0) {
          this.items = invoiceData?.data?.data.map((item) => {
            return {
              due_data: this.formatDate(item?.due_date),
              invoice_no: item?.invoice_no,
              sub_total_amount: item?.sub_total_amount +" "+(item?.currency),
              grand_total_amount:  item?.grand_total_amount +" "+(item?.currency),
            };
          });
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
  },
};
</script>

    <style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.table-custom-style-att {
  white-space: nowrap !important;
  min-height: 140px !important;
  tr,
  th,
  td {
    vertical-align: middle !important;
    text-align: center !important;
    font-size: 12px !important;
  }
}
.custom-font {
  font-size: 13px !important;
}
</style>
