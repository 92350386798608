var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('label', {
    staticClass: "custom-font mb-1"
  }, [_c('b', [_vm._v("Leave Balance Summary")])]), _c('b-table', {
    attrs: {
      "responsive": "sm",
      "items": _vm.items,
      "sticky-header": "",
      "height": "300px"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }