var render = function () {
  var _vm$assetStatusCount, _vm$assetStatusCount2, _vm$assetStatusCount3, _vm$assetStatusCount4, _vm$assetStatusCount5;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "card-statistics custom-card-statistics",
    staticStyle: {
      "margin-bottom": "10px !important"
    },
    attrs: {
      "no-body": ""
    }
  }, [_c('b-row', [_c('b-card-header', [_c('label', {
    staticClass: "custom-font"
  }, [_c('b', [_vm._v("All Asset Summary")])])]), _c('div', {
    staticClass: "button-style"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0 mr-1",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.goAsset
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "ClipboardIcon"
    }
  })], 1)], 1)], 1), _c('b-card-body', {
    staticClass: "statistics-body"
  }, [_c('b-row', [_c('b-media', {
    staticClass: "ml-1",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', [_c('b-avatar', {
    attrs: {
      "size": "28",
      "variant": "light-info"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "15",
      "icon": "HardDriveIcon"
    }
  })], 1)], 1), _c('b-media-body', {
    staticClass: "my-auto"
  }, [_c('h4', {
    staticClass: "font-weight-bolder mb-0"
  }, [_vm._v(_vm._s((_vm$assetStatusCount = _vm.assetStatusCount) === null || _vm$assetStatusCount === void 0 ? void 0 : _vm$assetStatusCount.total_category_count))]), _c('b-card-text', {
    staticClass: "font-small-3 mb-0"
  }, [_vm._v(" Categories ")])], 1)], 1), _c('b-media', {
    staticClass: "ml-1",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', [_c('b-avatar', {
    attrs: {
      "size": "28",
      "variant": "light-primary"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "15",
      "icon": "HardDriveIcon"
    }
  })], 1)], 1), _c('b-media-body', {
    staticClass: "my-auto"
  }, [_c('h4', {
    staticClass: "font-weight-bolder mb-0"
  }, [_vm._v(_vm._s((_vm$assetStatusCount2 = _vm.assetStatusCount) === null || _vm$assetStatusCount2 === void 0 ? void 0 : _vm$assetStatusCount2.total_count))]), _c('b-card-text', {
    staticClass: "font-small-3 mb-0"
  }, [_vm._v(" Total Asset Count ")])], 1)], 1), _c('b-media', {
    staticClass: "ml-1",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', [_c('b-avatar', {
    attrs: {
      "size": "28",
      "variant": "light-success"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "15",
      "icon": "HardDriveIcon"
    }
  })], 1)], 1), _c('b-media-body', {
    staticClass: "my-auto"
  }, [_c('h4', {
    staticClass: "font-weight-bolder mb-0"
  }, [_vm._v(_vm._s((_vm$assetStatusCount3 = _vm.assetStatusCount) === null || _vm$assetStatusCount3 === void 0 ? void 0 : _vm$assetStatusCount3.available_count))]), _c('b-card-text', {
    staticClass: "font-small-3 mb-0"
  }, [_vm._v(" Available Asset Count ")])], 1)], 1), _c('b-media', {
    staticClass: "ml-1",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', [_c('b-avatar', {
    attrs: {
      "size": "28",
      "variant": "light-danger"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "15",
      "icon": "HardDriveIcon"
    }
  })], 1)], 1), _c('b-media-body', {
    staticClass: "my-auto"
  }, [_c('h4', {
    staticClass: "font-weight-bolder mb-0"
  }, [_vm._v(_vm._s(((_vm$assetStatusCount4 = _vm.assetStatusCount) === null || _vm$assetStatusCount4 === void 0 ? void 0 : _vm$assetStatusCount4.total_count) - ((_vm$assetStatusCount5 = _vm.assetStatusCount) === null || _vm$assetStatusCount5 === void 0 ? void 0 : _vm$assetStatusCount5.available_count)))]), _c('b-card-text', {
    staticClass: "font-small-3 mb-0"
  }, [_vm._v(" Unavailable Asset Count ")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }