var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "card-transaction"
  }, [_c('b-row', [_c('label', {
    staticClass: "custom-font custom-title-margin"
  }, [_c('b', [_vm._v("Lead Contact Summary [7 days]")])]), _c('div', {
    staticClass: "button-style"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0 mr-1",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.goLead
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "ClipboardIcon"
    }
  })], 1)], 1)]), _c('b-card-body', _vm._l(_vm.contactCountData, function (data) {
    return _c('div', {
      key: data.key,
      staticClass: "transaction-item"
    }, [_c('b-media', {
      attrs: {
        "no-body": ""
      }
    }, [_c('b-media-aside', [_c('b-avatar', {
      attrs: {
        "rounded": "",
        "size": "28",
        "variant": data.avatarVariant
      }
    }, [_c('feather-icon', {
      attrs: {
        "size": "15",
        "icon": data.avatar
      }
    })], 1)], 1), _c('b-media-body', [_c('h5', {
      staticClass: "font-weight-bolder mb-0"
    }, [_vm._v(" " + _vm._s(data.action) + " ")]), _c('small', [_vm._v(_vm._s(data.text))])])], 1), _c('div', {
      staticClass: "font-weight-bolder"
    }, [_c('h4', {
      staticClass: "font-weight-bolder mb-0 text-primary"
    }, [[_vm._v(" " + _vm._s(data.count) + " ")]], 2)])], 1);
  }), 0)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }