var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.notOverdueData.length && _vm.overdueData.length ? _c('b-card', [_c('label', {
    staticClass: "custom-font"
  }, [_c('b', [_vm._v("Task Summary")])]), _c('br'), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', {
    staticClass: "mr-sm-1 mb-1 mb-sm-0 custom-font",
    attrs: {
      "options": _vm.dayRangeOptions,
      "reduce": function reduce(item) {
        return item.id;
      },
      "label": "name",
      "placeholder": "Filter by day range"
    },
    on: {
      "input": _vm.loadSelectedDayRange
    },
    model: {
      value: _vm.filterDayRange,
      callback: function callback($$v) {
        _vm.filterDayRange = $$v;
      },
      expression: "filterDayRange"
    }
  })], 1), _c('br'), _vm.loadTaskSummaryChart ? _c('div', [_c('app-echart-bar', {
    attrs: {
      "option-data": _vm.chartOption
    }
  })], 1) : _c('div', [[_c('div', {
    staticClass: "text-center"
  }, [_c('b-spinner', {
    attrs: {
      "variant": "primary",
      "label": "Text Centered"
    }
  })], 1)]], 2)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }