var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('label', {
    staticClass: "custom-font"
  }, [_c('b', [_vm._v("Income Summary (BDT) [Last 30 days]")])]), _vm.labels.length && _vm.data.length && _vm.maxSize ? _c('b-card-body', [_c('chartjs-component-bar-chart', {
    attrs: {
      "height": 400,
      "data": _vm.latestBarChart.data,
      "options": _vm.latestBarChart.options
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }