var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_c('b-avatar', {
    staticClass: "mb-1",
    attrs: {
      "variant": "light-".concat(_vm.color),
      "size": "45",
      "href": _vm.to
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "21",
      "icon": _vm.icon
    }
  })], 1), _c('div', {
    staticClass: "truncate"
  }, [_c('a', {
    staticClass: "font-weight-bolder",
    attrs: {
      "href": _vm.to
    }
  }, [[_vm._v(" " + _vm._s(_vm.statistic ? _vm.statistic : 0) + " ")]], 2), _c('a', {
    attrs: {
      "href": _vm.to
    }
  }, [_vm._v(" " + _vm._s(_vm.buttonName) + " ")])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }