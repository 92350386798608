var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('b-row', [_c('label', {
    staticClass: "custom-font"
  }, [_c('b', [_vm._v("Expense Summary (BDT) [Last 15 days]")])]), _c('div', {
    staticClass: "button-style"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0 mr-1",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.goExpense
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "ClipboardIcon"
    }
  })], 1)], 1)]), _vm.labels.length && _vm.data.length && _vm.maxSize ? _c('b-card-body', [_c('chartjs-component-bar-chart', {
    attrs: {
      "height": 400,
      "data": _vm.latestBarChart.data,
      "options": _vm.latestBarChart.options
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }