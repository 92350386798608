<template>
  <b-card
    no-body
    class="card-statistics custom-card-statistics"
    style="margin-bottom: 10px !important"
  >
    <b-row>
      <b-card-header>
        <label class="custom-font"><b>Lead Status Summary</b></label>
      </b-card-header>

      <div class="button-style">
        <b-button
          class="flex-shrink-0 mr-1"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="goLead"
        >
        <feather-icon icon="ClipboardIcon" />
        </b-button>
      </div>
    </b-row>

    <b-card-body class="statistics-body">
      <b-row>
        <b-media no-body class="ml-1">
          <b-media-aside>
            <b-avatar size="28" variant="light-primary">
              <feather-icon size="15" icon="DollarSignIcon" />
            </b-avatar>
          </b-media-aside>
          <b-media-body class="my-auto">
            <h4 class="font-weight-bolder mb-0 text-primary text-primary">
              <template>
                {{ leadStatusCount?.total_lead_count }}
              </template>
              <!-- <template>
                <b-spinner small variant="primary" />
              </template> -->
            </h4>
            <b-card-text class="font-small-3 mb-0"> Total </b-card-text>
          </b-media-body>
        </b-media>
        <b-media no-body class="ml-1">
          <b-media-aside>
            <b-avatar size="28" variant="light-success">
              <feather-icon size="15" icon="CheckCircleIcon" />
            </b-avatar>
          </b-media-aside>
          <b-media-body class="my-auto">
            <h4 class="font-weight-bolder mb-0 text-primary">
              <template>
                {{ leadStatusCount?.converted_lead_count }}
              </template>
              <!-- <template v-else>
                <b-spinner small variant="primary" />
              </template> -->
            </h4>
            <b-card-text class="font-small-3 mb-0"> Converted </b-card-text>
          </b-media-body>
        </b-media>
        <b-media no-body class="ml-1">
          <b-media-aside>
            <b-avatar size="28" variant="light-danger">
              <feather-icon size="15" icon="XOctagonIcon" />
            </b-avatar>
          </b-media-aside>
          <b-media-body class="my-auto">
            <h4 class="font-weight-bolder mb-0 text-primary">
              <template>
                {{ leadStatusCount?.lost_lead_count }}
              </template>
              <!-- <template v-else>
                <b-spinner small variant="primary" />
              </template> -->
            </h4>
            <b-card-text class="font-small-3 mb-0"> Lost </b-card-text>
          </b-media-body>
        </b-media>
        <b-media no-body class="ml-1">
          <b-media-aside>
            <b-avatar size="28" variant="light-info">
              <feather-icon size="15" icon="HelpCircleIcon" />
            </b-avatar>
          </b-media-aside>
          <b-media-body class="my-auto">
            <h4 class="font-weight-bolder mb-0 text-primary">
              <template
                v-if="
                  !isNaN(leadStatusCount?.total_lead_count) &&
                  !isNaN(leadStatusCount?.converted_lead_count) &&
                  !isNaN(leadStatusCount?.lost_lead_count)
                "
              >
                {{
                  leadStatusCount?.total_lead_count -
                  (leadStatusCount?.converted_lead_count +
                    leadStatusCount?.lost_lead_count)
                }}
              </template>
            </h4>
            <b-card-text class="font-small-3 mb-0"> Others </b-card-text>
          </b-media-body>
        </b-media>
        <b-media no-body class="ml-1">
          <b-media-aside>
            <b-avatar size="28" variant="light-warning">
              <feather-icon size="15" icon="PauseCircleIcon" />
            </b-avatar>
          </b-media-aside>
          <b-media-body class="my-auto">
            <h4 class="font-weight-bolder mb-0 text-primary">
              <template>
                {{ leadStatusCount?.not_contacted }}
              </template>
              <!-- <template v-else>
                <b-spinner small variant="primary" />
              </template> -->
            </h4>
            <b-card-text class="font-small-3 mb-0"> Not Contacted </b-card-text>
          </b-media-body>
        </b-media>
      </b-row>
    </b-card-body>
  </b-card>
</template>

  <script>
import {
  BCard,
  BRow,
  BCol,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BMedia,
  BMediaAside,
  BMediaBody,
  BFormSelect,
  BPagination,
  BTable,
  BFormCheckbox,
  BAvatar,
  BBadge,
  BFormGroup,
  BInputGroup,
  BLink,
  BSpinner,
  BButton,
} from "bootstrap-vue";

import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import { heightFade } from "@core/directives/animations";


export default {
  name: "LeadStatusCountWidget",
  components: {
    BSpinner,
    BCard,
    BRow,
    BCol,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    StatisticCardVertical,
    BFormSelect,
    BPagination,
    BTable,
    BFormCheckbox,
    BAvatar,
    BBadge,
    BFormGroup,
    BInputGroup,
    BLink,
    BButton,
  },

  directives: {
    Ripple,
    heightFade,
  },

  data() {
    return {
      leadStatusCount: {
        total_lead_count: 0,
        converted_lead_count: 0,
        lost_lead_count: 0,
        not_contacted: 0,
      },
    };
  },

  async created() {
    try {
      const leadStatusCount = await this.getLeadSummaryCount();

      this.leadStatusCount = leadStatusCount?.data;
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },
  methods: {
    async getLeadSummaryCount() {
      return await this.$api.get(
        "api/dashboard/admin-dashboard/lead-summary-count"
      );
    },

    goLead() {
      this.$router.push({ name: "admin-lead-management" });
    },
  },
};
</script>

  <style lang="scss">
.custom-card-statistics .statistics-body {
  padding: 0rem 2.4rem 1rem !important;
}
// .table-custom-style {
//   font-size: 13px !important;
//   white-space: nowrap !important;
//   min-height: 140px !important;
//   tr,
//   th,
//   td {
//     vertical-align: middle !important;
//     text-align: center !important;
//   }
// }

// .table-cell-margin {
//   margin: 8px 0px;
// }
.lead-table {
  padding: 0px !important;
}

.button-style {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 15px;
}
</style>
