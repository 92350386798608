<template>
  <b-card>
    <label class="custom-font"><b>Personal Work Hour Graph Summary</b></label>

    <!-- chart -->

    <div v-if="this.lineChartSimple.series[0].data.length > 0">
      <b-card-body>
      <vue-apex-charts
        type="line"
        height="400"
        :options="lineChartSimple.chartOptions"
        :series="lineChartSimple.series"
      />
    </b-card-body>
    </div>

  </b-card>
</template>

  <script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BBadge,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ex } from "@fullcalendar/core/internal-common";
import { $themeColors } from "@themeConfig";

export default {
  name: "WorkHourGraphWidget",

  components: {
    VueApexCharts,
    BCardHeader,
    BCard,
    BBadge,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
  },
  data() {
    return {
      lineChartSimple: {
        series: [
          {
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            zoom: {
              enabled: false,
            },
            toolbar: {
              show: false,
            },
          },
          markers: {
            strokeWidth: 7,
            strokeOpacity: 1,
            strokeColors: [$themeColors.light],
            colors: [$themeColors.warning],
          },
          colors: [$themeColors.warning],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "straight",
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          tooltip: {
            custom(data) {
              return `${'<div class="px-1 py-50"><span>'}${
                data.series[data.seriesIndex][data.dataPointIndex]
              } (h.m)</span></div>`;
            },
          },
          xaxis: {
            categories: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sept",
              "Oct",
              "Nov",
              "Dec",
            ],
          },
          yaxis: {
            // opposite: isRtl,
          },
        },
      },
    };
  },

  async created() {
    try {
      await this.loadItems();
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {
    async getWorkHourGraphSummary() {
      return this.$api.get("api/dashboard/work-hour-graph-summary");
    },

    async loadItems() {
      try {
        const workHourGraphSummary = await this.getWorkHourGraphSummary();
        const {
          Month_1, Month_2, Month_3,
          Month_4, Month_5, Month_6,
          Month_7, Month_8, Month_9,
          Month_10, Month_11, Month_12
        } = workHourGraphSummary?.data?.data;

        this.lineChartSimple.series[0].data = [
          Month_1, Month_2, Month_3,
          Month_4, Month_5, Month_6,
          Month_7, Month_8, Month_9,
          Month_10, Month_11, Month_12
        ];

      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
  },
};
</script>

  <style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
