<template>
  <b-card>

    <b-row>
      <label class="custom-font mb-1"
      ><b>Attendance Summary : {{ currentDate }}</b></label
    >

    <div class="button-style">
        <b-button
          class="flex-shrink-0 mr-1"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="goAttendance"
        >
        <feather-icon icon="ClipboardIcon" />
        </b-button>
      </div>
    </b-row>


    <div class="mb-1">
      <template v-if="attendanceStatusSummary?.present_info">
        <div>
          <b-badge
            variant="success"
            class="badge-glow mr-1"
            v-b-tooltip.hover
            :title="attendanceStatusSummary?.present_info?.info?.name"
            @click="filterByStatus(attendanceStatusSummary?.present_info?.info?.name)"
          >
            {{ attendanceStatusSummary?.present_info?.info?.sort_name }}
            {{ attendanceStatusSummary?.present_info?.count }}
          </b-badge>

          <b-badge
            variant="warning"
            class="badge-glow mr-1"
            v-b-tooltip.hover
            :title="attendanceStatusSummary?.leave_info?.info?.name"
            @click="filterByStatus(attendanceStatusSummary?.leave_info?.info?.name)"

          >
            {{ attendanceStatusSummary?.leave_info?.info?.sort_name }}
            {{ attendanceStatusSummary?.leave_info?.count }}
          </b-badge>
          <b-badge
            variant="danger"
            class="badge-glow mr-1"
            v-b-tooltip.hover
            :title="attendanceStatusSummary?.absent_info?.info?.name"
            @click="filterByStatus(attendanceStatusSummary?.absent_info?.info?.name)"

          >
            {{ attendanceStatusSummary?.absent_info?.info?.sort_name }}
            {{ attendanceStatusSummary?.absent_info?.count }}
          </b-badge>

          <b-badge
            variant="info"
            class="badge-glow mr-1"
            v-b-tooltip.hover
            :title="attendanceStatusSummary?.delay_info?.info?.name"
            @click="filterByStatus(attendanceStatusSummary?.delay_info?.info?.name)"

          >
            {{ attendanceStatusSummary?.delay_info?.info?.sort_name }}
            {{ attendanceStatusSummary?.delay_info?.count }}
          </b-badge>

          <b-badge
            variant="primary"
            class="badge-glow mr-1"
            v-b-tooltip.hover
            :title="attendanceStatusSummary?.extreme_delay_info?.info?.name"
            @click="filterByStatus(attendanceStatusSummary?.extreme_delay_info?.info?.name)"

          >
            {{ attendanceStatusSummary?.extreme_delay_info?.info?.sort_name }}
            {{ attendanceStatusSummary?.extreme_delay_info?.count }}
          </b-badge>
        </div>
      </template>
      <template v-else>
        <div class="attendance-spinner-parent">
          <b-spinner variant="primary" />
        </div>
      </template>
    </div>

    <b-table responsive="sm" :items="items" sticky-header height="300px">
      <!-- Your table columns go here -->
    </b-table>
  </b-card>
</template>

  <script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner,
  BCol,
  BRow,
  BFormDatepicker,
  BFormTextarea,
  BLink,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardText,
  BTable,
  VBTooltip,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import { EventBus } from "@/helpers/event-bus";

export default {
  name: "AttendanceTable",
  components: {
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
    BCol,
    BRow,
    BFormDatepicker,
    BFormTextarea,
    BLink,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardText,
    BTable,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      currentDate: "27th Dec, 2023",
      attendanceStatusSummary: {},
      attendanceStatus: [],
      filterWith: [],

      // filter
      items: [{ 
        name: "", 
        check_in: "   No Data Found", 
        check_out: "",
        status: "",
       }],
    };
  },

  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
  },

  async created() {
    try {
      const today = new Date();
      const formattedDate = today.toISOString().split("T")[0];

      this.currentDate = this.formatDate(formattedDate);

      this.loadItems();
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {
    formatDate(value) {
      if (value) {
        return this.$moment(value).format("MMM Do YYYY");
      }
      return "Not Set";
    },

    filterByStatus(status) {

      this.filterWith = status;

      if (status === "Present") {
        this.filterWith = [1,2,10,11];
      }else if (status === "Leave") {
        this.filterWith = [7,9];
      }else if (status === "Absent") {
        this.filterWith = [3,6,8];
      }else if (status === "Delay") {
        this.filterWith = [4,5,12,13];
      }else{
        this.filterWith = [14];
      }

      this.items = this.items.filter((item) => {
        return this.filterWith.includes(item.attendance_status_id);
      });
      
    },

    formatStatus(id){
      const status = this.attendanceStatus.find((status) => status.id === id);
      return status?.name;
    },

    goAttendance() {
      this.$router.push({ name: "admin-attendance" });
    },

    async getTodaysAttendance() {
      return await this.$api.get("api/dashboard/today-attendance");
    },
    async getTodaysAttendanceStatusCount() {
      return await this.$api.get("api/dashboard/today-attendance-status-count");
    },
    async getAttendanceStatus() {
      return await this.$api.get("api/dashboard/attendance-status/all");
    },
    async loadItems() {
      const [todayAttendance, statusCount, attendanceStatus] = await Promise.all([
        this.getTodaysAttendance(),
        this.getTodaysAttendanceStatusCount(),
        this.getAttendanceStatus(),
      ]);

      this.attendanceStatus = attendanceStatus?.data?.data;

      this.attendanceStatusSummary = statusCount?.data;
      if (todayAttendance?.data?.data.length > 0) {

        this.items = todayAttendance?.data?.data.map((item) => {
          return {
            name: item?.user?.name,
            check_in: this.formatTime(item?.first_check_in),
            check_out: this.formatTime(item?.last_check_out),
            status: this.formatStatus(item?.attendance_status_id),
          };
        });

      }
    },

    formatTime(value) {
      if (value) {
        return this.$moment(value, "HH:mm:ss").format("LTS");
      }
    },

    onShowPage(id) {
      this.$router.push({
        name: "admin-attendance-details",
        params: { id },
      });
    },
  },
};
</script>

  <style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.table-custom-style-att {
  white-space: nowrap !important;
  min-height: 140px !important;
  tr,
  th,
  td {
    vertical-align: middle !important;
    text-align: center !important;
    font-size: 12px !important;
  }
}
.custom-font {
  font-size: 13px !important;
}
.attendance-spinner-parent {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
