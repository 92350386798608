<template>
  <b-card>
    <label class="custom-font"><b>Income Summary (BDT) [Last 30 days]</b></label>

    <!-- chart -->
    <b-card-body v-if="labels.length && data.length && maxSize">
      <chartjs-component-bar-chart
        :height="400"
        :data="latestBarChart.data"
        :options="latestBarChart.options"
      />
    </b-card-body>
  </b-card>
</template>

  <script>
import { BCard, BCardHeader, BCardBody, BCardTitle } from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import ChartjsComponentBarChart from "@/layouts/components/dash-board/widgets/chart-js/ChartjsComponentBarChart.vue";
import { $themeColors } from "@themeConfig";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";


export default {
  name: "FinanceIncomeChartWidget",
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    flatPickr,
    ChartjsComponentBarChart,
  },
  data() {
    return {
      labels: [],
      data: [],
      maxSize: "",
    };
  },

  computed: {
    latestBarChart() {
      return {
        data: {
          labels: this.labels,
          datasets: [
            {
              data: this.data,
              backgroundColor: "#32cd32",
              borderColor: "transparent",
            },
          ],
        },
        options: {
          elements: {
            rectangle: {
              borderWidth: 2,
              borderSkipped: "bottom",
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            display: false,
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: "rgba(0, 0, 0, 0.25)",
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  display: true,
                  color: "rgba(200, 200, 200, 0.2)",
                  zeroLineColor: "rgba(200, 200, 200, 0.2)",
                },
                scaleLabel: {
                  display: false,
                },
                ticks: {
                  fontColor: "#6e6b7b",
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  color: "rgba(200, 200, 200, 0.2)",
                  zeroLineColor: "rgba(200, 200, 200, 0.2)",
                },
                ticks: {
                  stepSize: 100,
                  min: 0,
                  max: this.maxSize,
                  fontColor: "#6e6b7b",
                },
              },
            ],
          },
        },
      };
    },
  },

  async created() {
    try {
      await this.loadItems();
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {
    async getExpanseSummaryCount() {
      return await this.$api.get("api/dashboard/income-summary");
    },

    async loadItems() {
      try {
        const expanseSummaryCount = await this.getExpanseSummaryCount();

        this.labels = expanseSummaryCount?.data?.data?.date || [];
        this.data = expanseSummaryCount?.data?.data?.amount || [];
        this.maxSize = Math.max(...this.data);



      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
  },
};
</script>

  <style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
