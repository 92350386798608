<template>
  <b-card>
    <b-row>
      <label class="custom-font"><b>Personal Task Summary</b></label>

      <div class="button-style">
        <b-button
          class="flex-shrink-0 mr-1"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="goTask"
        >
        <feather-icon icon="ClipboardIcon" />

        </b-button>
      </div>
    </b-row>

    <!-- chart -->

    <div v-if="this.donutChart.series && this.donutChart.series.length > 0">
    <vue-apex-charts
        type="donut"
        height="350"
        :options="donutChart.chartOptions"
        :series="donutChart.series"
        />
    </div>
    
    
  </b-card>
</template>
  
  <script>
    
import { BButton, BCard, BCardTitle, BCardSubTitle, BRow } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ex } from '@fullcalendar/core/internal-common';
import Ripple from "vue-ripple-directive";
import { heightFade } from "@core/directives/animations";

export default {
  name: "TaskChartWidget",
  components: {
    VueApexCharts,
    BButton,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BRow,
  },

  directives: {
    Ripple,
    heightFade,
  },
  
  data() {
    return {

      notStarted: "",
      donutChart: {
        series: [],
        chartOptions: {
          legend: {
            show: true,
            position: "bottom",
            fontSize: "14px",
            fontFamily: "Montserrat",
          },
          colors: ["#FFA1A1", "#ffe700", "#00d4bd"],
          dataLabels: {
            enabled: true,
            formatter(val) {
              return `${parseInt(val)}%`;
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: "2rem",
                    fontFamily: "Montserrat",
                  },
                  value: {
                    fontSize: "1rem",
                    fontFamily: "Montserrat",
                    formatter(val) {
                      return `${parseInt(val)}`;
                    },
                  },
                  total: {
                    show: true,
                    fontSize: "1.5rem",
                    label: "Total Tasks",
                  },
                },
              },
            },
          },
          labels: ["Not Started", "Ongoing", "Finished"],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: "1.5rem",
                        },
                        value: {
                          fontSize: "1rem",
                        },
                        total: {
                          fontSize: "1.5rem",
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      },
    };
  },

  async created() {
    try {
      await this.loadItems();
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },


methods: {

  goTask() {
    this.$router.push({
        name: "employee-global-task-tracker",
      });
  },

  async getExpanseSummaryCount() {
      return  this.$api.get("api/dashboard/personal-task-summary");
    },

  async loadItems() {

    try {

      const expanseSummaryCount = await this.getExpanseSummaryCount();
     const { completed, notStarted, ongoing } = expanseSummaryCount?.data?.data;
      
      this.donutChart.series = [completed, notStarted, ongoing];

      // console.log(this.donutChart.series);

    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }


},

},
};
</script>
  
  <style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
  