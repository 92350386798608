var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('b-row', {
    staticStyle: {
      "margin-bottom": "10px"
    }
  }, [_c('label', {
    staticClass: "custom-font mb-1"
  }, [_c('b', [_vm._v("Due Invoice Summary [Last 30 Days]")])]), _c('div', {
    staticClass: "button-style"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0 mr-1",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.goInvoice
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "ClipboardIcon"
    }
  })], 1)], 1)]), _c('b-table', {
    attrs: {
      "responsive": "sm",
      "items": _vm.items,
      "sticky-header": "",
      "height": "300px"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }