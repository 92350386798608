var render = function () {
  var _vm$subModuleNameWith, _vm$subModuleNameWith2, _vm$subModuleNameWith3, _vm$subModuleNameWith4, _vm$subModuleNameWith5;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card', [_c('b-row', {
    staticClass: "match-height"
  }, [_c('b-col', [_c('statistic-card-vertical', {
    attrs: {
      "statistic": (_vm$subModuleNameWith = _vm.subModuleNameWithCount[0]) === null || _vm$subModuleNameWith === void 0 ? void 0 : _vm$subModuleNameWith.count,
      "color": "info",
      "icon": "BoxIcon",
      "button-name": "Division",
      "to": "/admin/division"
    }
  })], 1), _c('b-col', [_c('statistic-card-vertical', {
    attrs: {
      "color": "warning",
      "icon": "GridIcon",
      "statistic": (_vm$subModuleNameWith2 = _vm.subModuleNameWithCount[1]) === null || _vm$subModuleNameWith2 === void 0 ? void 0 : _vm$subModuleNameWith2.count,
      "button-name": "Department",
      "to": "/admin/department"
    }
  })], 1), _c('b-col', [_c('statistic-card-vertical', {
    attrs: {
      "color": "success",
      "icon": "AwardIcon",
      "statistic": (_vm$subModuleNameWith3 = _vm.subModuleNameWithCount[2]) === null || _vm$subModuleNameWith3 === void 0 ? void 0 : _vm$subModuleNameWith3.count,
      "button-name": "Designation",
      "to": "/admin/designation"
    }
  })], 1), _c('b-col', [_c('statistic-card-vertical', {
    attrs: {
      "color": "primary",
      "icon": "UsersIcon",
      "statistic": (_vm$subModuleNameWith4 = _vm.subModuleNameWithCount[3]) === null || _vm$subModuleNameWith4 === void 0 ? void 0 : _vm$subModuleNameWith4.count,
      "button-name": "Users",
      "to": "/admin/users"
    }
  })], 1), _c('b-col', [_c('statistic-card-vertical', {
    attrs: {
      "color": "danger",
      "icon": "ShieldIcon",
      "statistic": (_vm$subModuleNameWith5 = _vm.subModuleNameWithCount[4]) === null || _vm$subModuleNameWith5 === void 0 ? void 0 : _vm$subModuleNameWith5.count,
      "button-name": "Roles",
      "to": "/admin/Roles"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }