var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "lg": "12",
      "xs": "12"
    }
  }, [_c('DashboardWelcomeWidget')], 1), _vm.$permissionAbility(_vm.SUB_MODULES_COUNT_WIDGET, _vm.permissions) ? _c('b-col', {
    attrs: {
      "md": "12",
      "lg": "12",
      "xs": "12"
    }
  }, [_c('SubModulesCountWidget')], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_vm.$permissionAbility(_vm.LEAD_STATUS_COUNT_WIDGET, _vm.permissions) ? [_c('LeadStatusCountWidget')] : _vm._e(), _vm.$permissionAbility(_vm.LEAD_CONTACT_DAY_COUNT_WIDGET, _vm.permissions) ? [_c('LeadContactDayCountWidget')] : _vm._e(), _vm.$permissionAbility(_vm.ATTENDANCE_SUMMARY_COUNT, _vm.permissions) ? [_c('AttendanceTable')] : _vm._e(), _vm.$permissionAbility(_vm.EXPENSE_CHART_WIDGET, _vm.permissions) ? [_c('FinanceExpenseChartWidget')] : _vm._e(), _vm.$permissionAbility(_vm.INCOME_CHART_WIDGET, _vm.permissions) ? [_c('FinanceIncomeChartWidget')] : _vm._e(), _vm.$permissionAbility(_vm.UPCOMING_DUE_MILESTONE_WIDGET, _vm.permissions) ? [_c('UpcomingDueMilestoneWidget')] : _vm._e(), _vm.$permissionAbility(_vm.OVER_DUE_MILESTONE_WIDGET, _vm.permissions) ? [_c('OverDueMilestoneWidget')] : _vm._e(), _vm.$permissionAbility(_vm.UPCOMING_LEAVE_REQUEST_WIDGET, _vm.permissions) ? [_c('UpcomingLeaveRequestWidget')] : _vm._e(), _vm.$permissionAbility(_vm.LESS_WORK_HOUR_WIDGET, _vm.permissions) ? [_c('LessWorkHourWidget')] : _vm._e(), _vm.$permissionAbility(_vm.LOAN_SUMMARY_WIDGET, _vm.permissions) ? [_c('LoanSummaryWidget')] : _vm._e(), _vm.$permissionAbility(_vm.TASK_CHART_WIDGET, _vm.permissions) ? [_c('TaskChartWidget')] : _vm._e()], 2), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_vm.$permissionAbility(_vm.ASSET_SUMMARY_COUNT_WIDGET, _vm.permissions) ? [_c('AssetSummaryCountWidget')] : _vm._e(), _vm.$permissionAbility(_vm.PROJECT_SUMMARY_COUNT, _vm.permissions) ? [_c('FinanceProjectSummaryContVue')] : _vm._e(), _vm.$permissionAbility(_vm.TASK_SUMMARY_HORIZONTAL_BAR_CHART_WIDGET, _vm.permissions) ? [_c('TaskSummaryHorizontalBarChartWidget')] : _vm._e(), _vm.$permissionAbility(_vm.INCOME_AND_EXPENSE_WIDGET, _vm.permissions) ? [_c('FinanceIncomeAndExpenseWidget')] : _vm._e(), _vm.$permissionAbility(_vm.DUE_INVOICE_WIDGET, _vm.permissions) ? [_c('FinanceDueInvoiceWidget')] : _vm._e(), _vm.$permissionAbility(_vm.ACCOUNT_PAYABLE_WIDGET, _vm.permissions) ? [_c('FinanceAccountPayableWidget')] : _vm._e(), _vm.$permissionAbility(_vm.UPCOMING_AGREEMENT_RENEWAL_WIDGET, _vm.permissions) ? [_c('UpcomingAgreementRenewalWidget')] : _vm._e(), _vm.$permissionAbility(_vm.UPCOMING_HOLIDAY_WIDGET, _vm.permissions) ? [_c('UpcomingHolidayWidget')] : _vm._e(), _vm.$permissionAbility(_vm.NOTICE_BOARD_WIDGET, _vm.permissions) ? [_c('NoticeBoardWidget')] : _vm._e(), _vm.$permissionAbility(_vm.WORK_HOUR_GRAPH_WIDGET, _vm.permissions) ? [_c('WorkHourGraphWidget')] : _vm._e()], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }