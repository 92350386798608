var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('label', {
    staticClass: "custom-font mb-1"
  }, [_c('b', [_vm._v("Todays Attendance Summary")])]), _c('b-row', [_c('b-col', [_c('b-list-group', [_c('b-list-group-item', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('span', [_vm._v("Present")]), _c('b-badge', {
    staticClass: "badge-round",
    attrs: {
      "variant": "primary",
      "pill": ""
    }
  }, [_vm._v(" 8 ")])], 1), _c('b-list-group-item', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('span', [_vm._v("On Leave")]), _c('b-badge', {
    staticClass: "badge-round",
    attrs: {
      "variant": "primary",
      "pill": ""
    }
  }, [_vm._v(" 7 ")])], 1), _c('b-list-group-item', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('span', [_vm._v("Absent")]), _c('b-badge', {
    staticClass: "badge-round",
    attrs: {
      "variant": "primary",
      "pill": ""
    }
  }, [_vm._v(" 6 ")])], 1), _c('b-list-group-item', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('span', [_vm._v("Delay")]), _c('b-badge', {
    staticClass: "badge-round",
    attrs: {
      "variant": "primary",
      "pill": ""
    }
  }, [_vm._v(" 8 ")])], 1), _c('b-list-group-item', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('span', [_vm._v("Extreme Delay")]), _c('b-badge', {
    staticClass: "badge-round",
    attrs: {
      "variant": "primary",
      "pill": ""
    }
  }, [_vm._v(" 7 ")])], 1), _c('b-list-group-item', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('span', [_vm._v("Pending Leave Applications")]), _c('b-badge', {
    staticClass: "badge-round",
    attrs: {
      "variant": "primary",
      "pill": ""
    }
  }, [_vm._v(" 6 ")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }