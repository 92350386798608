<template>
  <b-card class="card-transaction">
    <b-row>
    <label class="custom-font custom-title-margin"><b>Lead Contact Summary [7 days]</b></label>

      <div class="button-style">
        <b-button
          class="flex-shrink-0 mr-1"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="goLead"
        >
        <feather-icon icon="ClipboardIcon" />
        </b-button>
      </div>
    </b-row>

    <b-card-body>
      <div
        v-for="data in contactCountData"
        :key="data.key"
        class="transaction-item"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar rounded size="28" :variant="data.avatarVariant">
              <feather-icon size="15" :icon="data.avatar" />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h5 class="font-weight-bolder mb-0">
              {{ data.action }}
            </h5>
            <small>{{ data.text }}</small>
          </b-media-body>
        </b-media>
        <div class="font-weight-bolder">
          <h4 class="font-weight-bolder mb-0 text-primary">
            <template>
              {{ data.count }}
            </template>
          </h4>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

  <script>
import {
  BCard,
  BRow,
  BCol,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner,
  BFormTextarea,
  BFormDatepicker,
  BLink,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BMedia,
  BMediaAside,
  BMediaBody,
  VBTooltip,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import { dashBoardNextTimeFilterConstants } from "@/helpers/constant/dashBoardNextTimeFilterConstant.js";
import { dashBoardPreviousTimeFilterConstants } from "@/helpers/constant/dashBoardPreviousTimeFilterConstant.js";
import { co } from '@fullcalendar/core/internal-common';

export default {
  name: "LeadContactDayCountWidget",
  components: {
    VueGoodTable,
    BLink,
    BForm,
    BButton,
    BCard,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BModal,
    BSpinner,
    BRow,
    BCol,
    BFormTextarea,
    BFormDatepicker,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      contactCountData: [
        {
          count: "0",
          action: "Contacted",
          text: "in Last 7 Days",
          avatar: "DollarSignIcon",
          avatarVariant: "light-success",
          key: "contacted_count",
        },
        {
          count: "0",
          action: "Not Contacted",
          text: "in Last 7 Days",
          avatar: "DollarSignIcon",
          avatarVariant: "light-danger",
          key: "not_contacted_count",
        },
        {
          count: "0",
          action: "To Be Contacted ",
          text: "in Next 7 Days",
          avatar: "DollarSignIcon",
          avatarVariant: "light-warning",
          key: "to_be_contacted_count",
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
  },
  created() {
    this.loadItems();
  },
  methods: {

    goLead() {
      this.$router.push({ name: "admin-lead-management" });
    },

    async getLeads() {
      return await this.$api.get(
        "api/dashboard/admin-dashboard/lead-contact-count"
      );
    },

    async loadItems() {
      try {
        const leads = await this.getLeads();
        const res = leads?.data;

        const resLookup = {};
        for (let key in res) {
          if (res.hasOwnProperty(key)) {
            resLookup[key] = res[key];
          }
        }

        this.contactCountData.forEach((item) => {
          const key = item.key;
          if (resLookup.hasOwnProperty(key)) {
            item.count = resLookup[key];
          }
        });
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },

  },
};
</script>

  <style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/pages/ui-feather.scss";

.table-custom-style-lead {
  // white-space: nowrap !important;
  min-height: 140px !important;
  tr,
  th,
  td {
    vertical-align: middle !important;
    text-align: center !important;
    font-size: 10px !important;
  }
}

.custom-font {
  font-size: 10 !important;
}

// .cell-with-description div {
//   margin: 2px 0px;
// }

.button-style {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 15px;
}
.custom-title-margin {
  margin-left: 15px;
  margin-bottom: 15px;
}
</style>
