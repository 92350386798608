<template>
  <div class="text-center">
    <b-avatar class="mb-1" :variant="`light-${color}`" size="45" :href="to">
      <feather-icon size="21" :icon="icon" />
    </b-avatar>
    <div class="truncate">
      <a class="font-weight-bolder" :href="to">
        <template>
          {{ statistic ? statistic : 0 }}
        </template>
      </a>
      <a :href="to">
        {{ buttonName }}
      </a>
      <!-- <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        size="sm"
        variant="primary"
        block
        :href="to"
      >
        {{ buttonName }}
      </b-button> -->
    </div>
  </div>
</template>

<script>
import { BButton, BCard, BAvatar, BSpinner } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BButton,
    BCard,
    BAvatar,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    statistic: {
      // type: [Number, String],
      // required: true,
    },
    color: {
      type: String,
      default: "primary",
    },
    to: {
      type: String,
      default: "/",
    },
    buttonName: {
      type: String,
      default: "Go",
    },
  },
};
</script>

<style lang="scss">
a.font-weight-bolder {
    font-size: xx-large;
    color: #5e5873;
    display: block;
}
</style>
