<template>
  <!-- badge -->
  <b-card>
    <label class="custom-font mb-1"><b>Todays Attendance Summary</b></label>
    <b-row>
      <b-col>
        <b-list-group>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <span>Present</span>
            <b-badge variant="primary" pill class="badge-round"> 8 </b-badge>
          </b-list-group-item>

          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <span>On Leave</span>
            <b-badge variant="primary" pill class="badge-round"> 7 </b-badge>
          </b-list-group-item>

          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <span>Absent</span>
            <b-badge variant="primary" pill class="badge-round"> 6 </b-badge>
          </b-list-group-item>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <span>Delay</span>
            <b-badge variant="primary" pill class="badge-round"> 8 </b-badge>
          </b-list-group-item>

          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <span>Extreme Delay</span>
            <b-badge variant="primary" pill class="badge-round"> 7 </b-badge>
          </b-list-group-item>

          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <span>Pending Leave Applications</span>
            <b-badge variant="primary" pill class="badge-round"> 6 </b-badge>
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <!-- <b-col>
        <b-list-group>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <span>Delay</span>
            <b-badge variant="primary" pill class="badge-round"> 8 </b-badge>
          </b-list-group-item>

          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <span>Extreme Delay</span>
            <b-badge variant="primary" pill class="badge-round"> 7 </b-badge>
          </b-list-group-item>

          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <span>Pending Leave Applications</span>
            <b-badge variant="primary" pill class="badge-round"> 6 </b-badge>
          </b-list-group-item>
        </b-list-group>
      </b-col> -->
    </b-row>
  </b-card>
</template>

<script>
import {
  BListGroup,
  BListGroupItem,
  BBadge,
  BCardBody,
  BCard,
  BRow,
  BCol,
} from "bootstrap-vue";

export default {
  name: "AttendanceSummaryCount",
  components: {
    BBadge,
    BListGroup,
    BListGroupItem,
    BCardBody,
    BCard,
    BRow,
    BCol,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.table-custom-style-att {
  white-space: nowrap !important;
  min-height: 140px !important;
  tr,
  th,
  td {
    vertical-align: middle !important;
    text-align: center !important;
    font-size: 12px !important;
  }
}
.custom-font {
  font-size: 13px !important;
}
</style>
