var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('label', {
    staticClass: "custom-font"
  }, [_c('b', [_vm._v("Personal Work Hour Graph Summary")])]), this.lineChartSimple.series[0].data.length > 0 ? _c('div', [_c('b-card-body', [_c('vue-apex-charts', {
    attrs: {
      "type": "line",
      "height": "400",
      "options": _vm.lineChartSimple.chartOptions,
      "series": _vm.lineChartSimple.series
    }
  })], 1)], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }